/*
 * form.js - Adds the date domain list widget to sao
 */
'use strict';

Sao.View.Form.DateDomain2Many = Sao.class_(Sao.View.Form.Many2Many, {
    class_: 'form-datedomain2many',
    _date_format: Sao.common.format_date,
    _date_parse: Sao.common.parse_date,
    init: function(view, attributes) {
        Sao.View.Form.DateDomain2Many._super.init.call(this, view, attributes);

        this.date_field = attributes.date_field || 'date';
        this.date_field_type = attributes.date_field_type || 'date';
        this.tab_domains = eval_pyson(attributes.tab_domains) || [];

        this.title.hide();
        this.menu.children('.' + this.class_ + '-toolbar').hide();

        this.screen.domain = [['id', '=', -1]];

        var date = jQuery('<div/>', {
            'class': (
                'input-group input-group-sm ' +
                'input-icon input-icon-secondary'),
        }).appendTo(this.menu);

        this.date_previous = jQuery('<button/>', {
            'type': 'button',
            'class': 'btn btn-default btn-sm',
            'aria-label': Sao.i18n.gettext('Previous'),
            'title': Sao.i18n.gettext("Previous"),
        }).appendTo(date);
        this.date_previous_icon = jQuery('<img/>', {
            'class': 'icon',
        }).prependTo(this.date_previous);
        this.date_previous_icon.hide();
        this.date_previous_icon.attr('aria-hidden', true);
        Sao.common.ICONFACTORY.get_icon_url('tryton-back').done(url => {
            this.date_previous_icon.attr('src', url);
            this.date_previous_icon.show();
        });
        this.date_previous.click(() => {
            var date = this._date_parse(this.get_date_format(), this.date.val()) || Sao.DateTime();
            date.subtract(1, "days");
            this.change_date(date);
        });

        var date_input = jQuery('<div/>', {
            'class': 'form-date',
        }).appendTo(date);
        this.date = jQuery('<input/>', {
            'type': 'text',
            'class': 'form-control input-sm mousetrap',
            'name': attributes.date,
        }).appendTo(date_input);

        this.date_next = jQuery('<button/>', {
            'type': 'button',
            'class': 'btn btn-default btn-sm',
            'aria-label': Sao.i18n.gettext('Next'),
            'title': Sao.i18n.gettext("Next"),
        }).appendTo(date);
        this.date_next_icon = jQuery('<img/>', {
            'class': 'icon',
        }).prependTo(this.date_next);
        this.date_next_icon.hide();
        this.date_next_icon.attr('aria-hidden', true);
        Sao.common.ICONFACTORY.get_icon_url('tryton-forward').done(url => {
            this.date_next_icon.attr('src', url);
            this.date_next_icon.show();
        });
        this.date_next.click(() => {
            var date = this._date_parse(this.get_date_format(), this.date.val()) || Sao.DateTime();
            date.add(1, "days");
            this.change_date(date);
        });

        this.date_calendar = jQuery('<input/>', {
            'type': 'date',
            'role': 'button',
            'tabindex': -1,
        });
        this.date_calendar.click(() => {
            var value = this._date_parse(this.get_date_format(), this.date.val());
            value = this._date_format('%Y-%m-%d', value);
            this.date_calendar.val(value);
        });
        this.date_calendar.change(() => {
            var value = this._date_parse('%Y-%m-%d', this.date_calendar.val());
            this.change_date(value);
        });
        this.icon = jQuery('<div/>', {
            'class': 'icon-input icon-secondary',
            'aria-label': Sao.i18n.gettext("Open the calendar"),
            'title': Sao.i18n.gettext("Open the calendar"),
            'tabindex': -1,
        }).appendTo(date_input);
        this.date_calendar.appendTo(this.icon);
        Sao.common.ICONFACTORY.get_icon_img('tryton-date')
            .appendTo(this.icon);
        this.date.focusout(() => {
            var date = this._date_parse(this.get_date_format(), this.date.val());
            this.change_date(date);
        });

        var mousetrap = new Mousetrap(this.date[0]);
        mousetrap.bind('=', (e, combo) => {
            e.preventDefault();
            this.date.val(this._date_format(this.get_date_format(), moment())).change();
        });
        Sao.common.DATE_OPERATORS.forEach(operator => {
            mousetrap.bind(operator[0], (e, combo) => {
                e.preventDefault();
                var date = this._date_parse(this.get_date_format(), this.date.val()) || Sao.DateTime();
                date.add(operator[1]);
                this.change_date(date);
            });
        });
        this.date.val(this._date_format(this.get_date_format(), moment())).change();

        this.tabs = jQuery('<ul/>', {
            'class': 'nav nav-tabs',
            'role': 'tablist'
        }).appendTo(this.menu);
        for (var tab_domain of this.tab_domains) {
            var tab = jQuery('<li/>', {
                'role': 'presentation'
            }).appendTo(this.tabs);
            var tab_link = jQuery('<a/>', {
                'href': '#',
                'role': 'tab'
            }).text(tab_domain[0]).appendTo(tab);
            tab_link.click((e) => {
                this.change_tab(e.target);
            });
        }
        if (this.tab_domains) {
            this.change_tab(this.tabs.find('a:first')[0]);
        }
    },
    get_date_format: function() {
        return Sao.common.date_format(this.view.screen.context.date_format);
    },
    change_date: function(date) {
        var value = this._date_format('%Y-%m-%d', date);
        if (value) {
            value = this._date_parse('%Y-%m-%d', value);
            value = this._date_format(this.get_date_format(), value);
            this.date.val(value).change();
            if (!~navigator.userAgent.indexOf("Firefox")) {
                // Firefox triggers change when navigating by month/year
                this.date.focus();
            }
        }
        this.display();
    },
    get_date_domain: function() {
        if (!this.date_field && !this.datetime_field) {
            return [];
        }

        var date = this._date_parse(this.get_date_format(), this.date.val());
        if (date) {
            var next_date = Sao.Date(date.year(), date.month(), date.date()).add(1, 'days');
            if (this.date_field_type == 'datetime') {
                date = Sao.DateTime(date.year(), date.month(), date.date(), 0, 0, 0, 0);
                next_date = Sao.DateTime(next_date.year(), next_date.month(), next_date.date(), 0, 0, 0, 0);
            }
            return [
                'AND',
                [this.date_field, '>=', date],
                [this.date_field, '<', next_date]
            ];
        } else {
            return [[this.date_field, '=', null]];
        }
    },
    change_tab: function(link) {
        this.tabs.children().each(function(i, val) {
            var tab = jQuery(val);
            var active = link.parentElement === tab[0];
            if (!tab.hasClass('active') && active) {
                tab.addClass('active');
            } else if (tab.hasClass('active') && !active) {
                tab.removeClass('active');
            }
        });
        this.display();
    },
    get_tab_domain: function() {
        if (!this.tab_domains) {
            return [];
        }
        var idx = this.tabs.find('li').index(this.tabs.find('li.active'));
        if (idx < 0) {
            return [];
        }
        return this.tab_domains[idx][1];
    },
    display: function() {
        this.prm.done(() => {
            var record = this.record;
            var field = this.field;

            if (!field) {
                this.screen.new_group();
                this.screen.current_record = null;
                this.screen.group.parent = null;
                this.screen.display();
                return;
            }
            this.screen.group.parent = null;
            this.screen.domain = [
                [this.attributes.relation_field, '=', this.record.id],
                this.get_date_domain(),
                this.get_tab_domain()
            ];
            this.screen.search_filter().then(() => {
                this.screen.display();
                if (this.attributes.height !== undefined) {
                    this.screen.current_view.el
                        .find('.treeview,.list-form').first()
                        .css('min-height', this.attributes.height + 'px')
                        .css('max-height', this.attributes.height + 'px');
                }
                });
            this.screen.group.parent = this.record;
        });
    }
});

Sao.View.FormXMLViewParser.WIDGETS.datedomain2many = Sao.View.Form.DateDomain2Many;
